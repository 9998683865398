:root {
  --primary_color: #417154;
  --text_color: #72655b;
  --background_color: #c7cedc;
}

body {
  background-color: var(--primary_color);
  color: var(--text_color);
  margin: 0;
  padding: 0;
  font-family: Courier New, monospace;
}

.header {
  background-color: var(--primary_color);
  color: var(--text_color);
  align-items: center;
  padding: 8px;
  display: flex;
}

.header_name {
  color: #000;
  margin: 0;
  padding: 10px;
}

.header_link {
  border: none;
  width: 24px;
  height: 24px;
  margin: 2px;
  padding: 7px;
}

.header_link:hover {
  cursor: pointer;
  background: var(--text_color);
}

.header_right {
  margin-left: auto;
  display: flex;
}

.content {
  background-color: var(--background_color);
  padding: 20px;
  display: flex;
}

.profile {
  flex-direction: column;
  align-items: center;
  display: flex;
}

hr.rounded_big {
  border-top: 5px solid #727070;
  border-radius: 5px;
}

hr.rounded_small {
  border-top: 3px solid #727070;
  border-radius: 3px;
}

.profile_skills {
  margin: 0 10px;
  font-size: 20px;
  display: flex;
}

.resume_section_title {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.person_icon, .work_exp_icon {
  border: none;
  width: 48px;
  height: 48px;
}

.profile_pic {
  border-radius: 12%;
  width: 200px;
  height: 200px;
  margin: 0 10px;
}

.footer {
  background-color: var(--primary_color);
  color: #000;
  text-align: center;
  flex-direction: column;
  padding: 10px;
  display: flex;
}

.footer_copy_right {
  font-weight: bold;
}

.resume {
  margin: 0 20px;
}

#about {
  font-weight: bold;
}

@media screen and (width <= 720px) {
  .content {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .profile_pic {
    width: 42%;
    height: auto;
    margin: 0;
  }
}
/*# sourceMappingURL=repo.598f0180.css.map */
