:root {
    --primary_color: #417154; /* rgb(65, 113, 84)  |  header, footer,  */
    --text_color: #72655b; /* rgb(166, 123, 92)  |  text, highlighting */
    --background_color: #c7cedc; /* rgb(245, 245, 245)  |  background of content, main parts */
}

body {
    font-family: 'Courier New', monospace;
    margin: 0;
    padding: 0;
    background-color: var(--primary_color); /* must be same as header, check content bg-color */
    color : var(--text_color);
}

.header {
    background-color: var(--primary_color);
    color: var(--text_color);
    display: flex;
    align-items: center;
    padding: 8px;
}

.header_name {
    color: black;
    margin: 0;
    padding: 10px;
}

.header_link {
    width: 24px;
    height: 24px;
    margin: 2px;
    padding: 7px;
    border: none;
}

.header_link:hover {
    cursor: pointer;
    background: var(--text_color);
}

.header_right {
    margin-left: auto;
    display: flex;
}

.content {
    display: flex;
    padding: 20px;
    background-color: var(--background_color);
}

.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

hr.rounded_big {
    border-top: 5px solid #727070;
    border-radius: 5px;
}

hr.rounded_small {
    border-top: 3px solid #727070;
    border-radius: 3px;
}

.profile_skills {
    display: flex;
    margin: 0 10px;
    font-size: 20px;
}

.resume_section_title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.person_icon {
    width: 48px;
    height: 48px;
    border: none;
}

.work_exp_icon {
    width: 48px;
    height: 48px;
    border: none;
}

.profile_pic {
    width: 200px;
    height: 200px;
    border-radius: 12%;
    margin: 0 10px;
}

.footer {
    background-color: var(--primary_color);
    color: black;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
}

.footer_text {

}

.footer_copy_right {
    font-weight: bold;
}

.resume {
    margin: 0 20px;
}

#about {
    font-weight: bold;
}

@media screen and (max-width: 720px) {
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile_pic {
        width: 42%;
        height: auto;
        margin: 0;
    }
}
